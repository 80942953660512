import styles from './Helm.module.scss';

import logo from '../../../assets/logo_white.webp';
import searchIcon from '../../../assets/icons/search-normal.svg';

import avatarPlaceHolderIcon from '../../../assets/icons/avatar-placeholder.svg';
import messageIcon from '../../../assets/icons/message.svg';
import notificationIcon from '../../../assets/icons/notification.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import {
  MainPanelSegments,
  MenuOption,
  menuOptions,
  menuOptionsDropdown,
  NotificationType,
} from '../../../global/helpers/constants';
import _ from 'lodash';
import {
  purgeData,
  useAppDispatch,
  useAppSelector,
} from '../../../global/GlobalStore';
import request, {
  trimTextWithHtml,
  useInitiateHelmet,
} from '../../../global/helpers/utils';
import {
  Alert,
  Backdrop,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
} from '@mui/material';
import {
  closeNotification,
  setCallBackModal,
  setCurrentMainPanelSegment,
  setGlobalLoading,
} from '../../../global/reducers/GlobalReducer';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useInitiateSocket,
  useNotificationListener,
} from '../../../global/helpers/socket_context';

import likeShapes from '../../../assets/icons/like-shapes.svg';
import CircleLoader from '../../../components/Loader/CircleLoader';
import ServiceModal from '../services-panel/service-modal/ServiceModal';
import { AnimatePresence, motion } from 'framer-motion';
import // NotificationType,
  // USER_PERMISSIONS
  '@web-app/common';
import { setData } from '../../../global/reducers/ServiceListReducer';
import { NotificationItem } from '../../../global/reducers/NotificationReducer';
import { BellIcon } from '../../../components/Icon/Icon';
import { Message, User } from '../../../global/reducers/MessageCenterReducer';
import Lightbox, { Slide } from 'yet-another-react-lightbox';
import { handleLightBox } from '../../../global/reducers/ServiceModalReducer';

import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import BurgerButton from '../../../components/Burger/BurgerButton';
import ReserveVisitModal from '../services-panel/service-modal/ReserveVisitModal';
// import { USER_PERMISSIONS } from '@web-app/common';
import { useMount } from 'react-use';
import { PaymentInfoModalInnerData } from '../profile/edit/reservations-panel/ReservationsPanel';
import { setUserData } from '../../../global/reducers/UserReducer';
import { setData as setSubscriptionData } from "../../../global/reducers/SubscriptionReducer";
import SubscriptionModalInner from '../../subscription/subscription-modal/SubscriptionModal';


type HelmProps = {
  children?: React.ReactNode;
};

const Helm: React.FC<HelmProps> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);
  const [messagesAnchorEl, setMessagesAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);

  // const { permissions } = useAppSelector((state) => state.user);
  const { notifications } = useAppSelector((state) => state.notification);
  const { chatRooms } = useAppSelector((state) => state.messageCenter);

  // const isWorker = useMemo(
  //   () => _.some(permissions, (p) => p === USER_PERMISSIONS.worker),
  //   [permissions]
  // );

  const [isMobileBurgerOpen, setIsMobileBurgerOpen] = useState(false);

  const navigate = useNavigate();

  const {
    currentMainPanelSegment,
    notification,
    segmentHeaderData,
    globalLoading,
  } = useAppSelector((state) => state.global);
  const userData = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  useInitiateSocket();
  useNotificationListener();
  const [helmetInitialized] = useInitiateHelmet();

  const handleChangeSegment = useCallback(
    async (option: MenuOption) => {
      if (option.value === MainPanelSegments.LOGOUT) {
        purgeData();
        await request(
          { method: 'POST', url: '/auth/logout' },
          dispatch,
          false,
          () => { },
          undefined,
          5
        );
        navigate('/login');
        return;
      }

      dispatch(setCurrentMainPanelSegment({ segment: option.value }));
    },
    [dispatch, navigate]
  );

  const handleSettingsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleMessagesClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagesAnchorEl(event.currentTarget);
  };

  const handleMobBurger = () => setIsMobileBurgerOpen(!isMobileBurgerOpen);

  const handlePartnershipSettings = () => {
    handleChangeSegment({
      label: 'Inkspiration Business',
      value: MainPanelSegments.SUBSCRIPTION,
    });
  };

  const handlePartnershipCheck = async () => {
    dispatch(setGlobalLoading(true));


    const response = await request<{
      subscriptionData: {
        active: boolean;
        portalUrl: string;
        isPartOfCompany: boolean;
      }
    }>({
      url: '/v3/private/subscription',
      method: 'GET',
    }, dispatch, false);

    dispatch(setUserData({ hasSubscriptionActive: response.subscriptionData.active }));
    dispatch(setSubscriptionData({ isSubscriptionActive: response.subscriptionData.active, billingPortalUrl: response.subscriptionData.portalUrl, isSubscriptionOwner: !response.subscriptionData.isPartOfCompany }));

    if (!response.subscriptionData.active) {
      // open modal with data and link to redirect to billing page

      // dispatch(setCurrentMainPanelSegment({ segment: MainPanelSegments.PROFILE }));
      setIsSubscriptionOpen(true);
      dispatch(setGlobalLoading(false));
      // window.location.href = response.subscriptionData.portalUrl;

      return;
    }



    dispatch(setGlobalLoading(false));
    // if has partnership enter
    handlePartnershipSettings();
  }

  return (
    <>
      <div className={styles.helmBody}>
        <div className={styles.headerContainer}>
          {/* TOP  */}
          <div className={styles.topBarBox}>
            <div className={styles.logo}>
              <img alt='logo' src={logo} />
            </div>
            <SearchInput />
            <div className={styles.settingsBar}>
              <div
                className={`${styles.iconBox} ${currentMainPanelSegment === MainPanelSegments.MESSAGES_CENTER
                  ? styles.iconBox_active
                  : ''
                  }`}
                id='dropdown_btn_messages'
                onClick={handleMessagesClick}
              >
                <img
                  alt='messages-icon'
                  src={messageIcon}
                  className={styles.icon}
                />
              </div>
              <Menu
                id='dropdown_btn_messages'
                anchorEl={messagesAnchorEl}
                open={Boolean(messagesAnchorEl)}
                onClose={() => setMessagesAnchorEl(null)}
                classes={{
                  paper: styles.dropdownMenu,
                }}
              >
                {chatRooms.length > 0 ? (
                  <div>
                    <div className={styles.messagesContainer}>
                      {_(chatRooms)
                        .take(3)
                        .map(
                          (chatRoom, index) =>
                            chatRoom?.users?.length > 0 && (
                              <MessageMenuItem
                                user={chatRoom.users.find(
                                  (u) => u.id !== userData.id
                                )}
                                {...chatRoom}
                                key={index}
                              />
                            )
                        )
                        .value()}
                    </div>
                    <button
                      className={styles.dropdownNotfBtn}
                      onClick={() => {
                        handleChangeSegment({
                          label: 'Pranešimų centras',
                          underline:
                            'Sekite naujienas ir pranešimus nuo kitų vartotojų',
                          value: MainPanelSegments.MESSAGES_CENTER,
                        });
                        setMessagesAnchorEl(null);
                      }}
                    >
                      Peržiūrėti visus pokalbius
                    </button>
                  </div>
                ) : (
                  <div className={styles.simpleText}>Nėra aktyvių pokalbių</div>
                )}
              </Menu>
              <div
                className={`${styles.iconBox} ${currentMainPanelSegment === MainPanelSegments.NOTIFICATIONS
                  ? styles.iconBox_active
                  : ''
                  }`}
                id='dropdown_btn_notification'
                onClick={handleNotificationsClick}
              >
                <img
                  alt='notification-icon'
                  src={notificationIcon}
                  className={styles.icon}
                />
              </div>
              <Menu
                id='dropdown_btn_notification'
                anchorEl={notificationAnchorEl}
                open={Boolean(notificationAnchorEl)}
                onClose={() => setNotificationAnchorEl(null)}
                classes={{
                  paper: styles.dropdownMenu,
                }}
              >
                {notifications.length ? (
                  <div className={styles.notificationContainer}>
                    {_.map(_.take(notifications, 3), (n, index) => (
                      <Fragment key={`notification-${index}`}>
                        <NotificationMenuItem {...n} />
                        {index !== notifications.length - 1 && (
                          <div className={styles.notificationDivider} />
                        )}
                      </Fragment>
                    ))}
                    <button
                      className={styles.dropdownNotfBtn}
                      onClick={() => {
                        handleChangeSegment({
                          label: 'Pranešimai',
                          value: MainPanelSegments.NOTIFICATIONS,
                        });
                        setNotificationAnchorEl(null);
                      }}
                    >
                      Peržiūrėti visus pranešimus
                    </button>
                  </div>
                ) : (
                  <div className={styles.simpleText}>
                    Nėra aktyvių pranešimų
                  </div>
                )}
              </Menu>
              <div
                className={`${styles.iconBox} ${currentMainPanelSegment ===
                  MainPanelSegments.SERVICES_IN_MEMORY
                  ? styles.iconBox_active
                  : ''
                  }`}
                onClick={() =>
                  handleChangeSegment({
                    label: 'Įsiminti skelbimai',
                    underline: 'Įsiminti skelbimai',
                    value: MainPanelSegments.SERVICES_IN_MEMORY,
                  })
                }
              >
                <img alt='love-icon' src={heartIcon} className={styles.icon} />
              </div>
              <div
                className={styles.avatarBox}
                onClick={handleSettingsClick}
                id='dropdown_btn'
              >
                <img
                  alt='avatar-icon'
                  src={userData.avatarFileName ?? avatarPlaceHolderIcon}
                  className={styles.avatarIcon}
                />
              </div>
              <Menu
                id='dropdown_btn'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{
                  paper: styles.dropdownMenu,
                }}
              >
                <div className={styles.dropdownUpperContainer}>
                  <div
                    className={styles.dropdownAvatar}
                    style={{
                      background: `url(${userData.avatarFileName ?? avatarPlaceHolderIcon
                        }) lightgray 50% / cover no-repeat`,
                    }}
                  />
                  <div>
                    <p className={styles.userName}>{userData.name}</p>
                    <p className={styles.rating}>
                      <img src={likeShapes} alt='tradeMark' />
                      {userData.rating}
                      <span>({userData.ratingCount} Atsiliepimų)</span>
                    </p>
                  </div>
                </div>
                <div className={styles.dropdownLowerContainer}>
                  {_.map(menuOptionsDropdown, (option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleChangeSegment(option);
                        setAnchorEl(null);
                      }}
                      className={`${styles.dropdownMenuItem} ${option.value === currentMainPanelSegment
                        ? styles.dropdownMenuItem__active
                        : ''
                        }`}
                    >
                      {option.icon}
                      <span>{option.label}</span>
                    </MenuItem>
                  ))}
                </div>
              </Menu>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.pageHeaderBox}>
            <AnimatePresence mode='wait'>
              <motion.div
                key={segmentHeaderData?.label}
                layout
                initial={{ x: -100, opacity: 0.3 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 100, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={styles.pageHeader}
              >
                <p className={styles.pageHeaderTitle}>
                  {segmentHeaderData?.label}
                </p>
                <p className={styles.pageHeaderUnderline}>
                  {segmentHeaderData?.underline}
                </p>
              </motion.div>
            </AnimatePresence>
            {/* Navigation */}
            <nav className={styles.navigation}>
              <div className={styles.navigation_mob_burger}>
                <BurgerButton
                  onClick={handleMobBurger}
                  burgerStyleType='cross-middle'
                  isOpen={isMobileBurgerOpen}
                />
              </div>
              <div
                className={`${styles.navigationBox} ${isMobileBurgerOpen ? styles.navigationBurgerOpen : ''
                  }`}
              >
                {_.map(menuOptions, (option, index) => (
                  <motion.div
                    key={index}
                    className={styles.navigationItem}
                    onClick={() => {
                      handleChangeSegment(option);
                      setIsMobileBurgerOpen(false);
                    }}
                  >
                    <p>{option.label}</p>
                    {currentMainPanelSegment === option.value ? (
                      <motion.div
                        className={styles.navigationActiveDivider}
                        layoutId='navigationLayoutItem'
                      />
                    ) : (
                      <div className={styles.navicationInActiveDivider} />
                    )}
                  </motion.div>
                ))}
                <div
                  className={styles.btnBuyPartnership}
                  onClick={handlePartnershipCheck}
                >
                  <p>InkSpiration Business</p>
                </div>
              </div>

            </nav>
            {/* {isWorker && ( */}

            {/* )} */}
          </div>
        </div>
        {/* Load children only when helmet is ready */}
        {helmetInitialized && children}
      </div>
      <ServiceModal />
      <ReserveVisitModal />
      <Snackbar
        sx={{
          zIndex: 9999,
        }}
        open={notification.isOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeNotification())}
      >
        <Alert
          severity={notification?.severity || 'info'}
          variant='standard'
          onClose={() => dispatch(closeNotification())}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
      <Backdrop
        open={Boolean(globalLoading)}
        sx={{ backdropFilter: 'blur(10px) sepia(0.3)', zIndex: 9999 }}
      >
        <CircleLoader width={150} />
      </Backdrop>
      <LightBoxElement />
      <CallbackMainModal />
      <SubscriptionModal isOpen={isSubscriptionOpen} onClose={() => setIsSubscriptionOpen(false)} />
    </>
  );
};

export default Helm;

const SearchInput: React.FC = () => {
  const dispatch = useAppDispatch();

  const [text, setText] = useState<string>();

  const handleSearch = () => {
    if (text && text?.length < 4) {
      // we shall not let abuse it!
      return;
    }

    dispatch(
      setData({
        selectedUserId: undefined,
        selectedCity: undefined,
        selectedExperience: undefined,
        selectedPriceRange: undefined,
        selectedUserName: text,
        otherPageSetFilter: true,
      })
    );
    dispatch(
      setCurrentMainPanelSegment({
        segment: MainPanelSegments.USER_SERVICES,
      })
    );
  };

  return (
    <div className={styles.searchBox}>
      <input
        autoCapitalize='off'
        spellCheck='false'
        type='text'
        placeholder='Ieškoti tatuiruočių meistro, salono'
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
      />
      <div
        style={{ backgroundImage: `url(${searchIcon})` }}
        className={styles.searchIcon}
        onClick={handleSearch}
      />
    </div>
  );
};

const NotificationMenuItem: React.FC<NotificationItem> = ({
  notificationType,
  data,
  createdOn,
}) => {
  const notificationText = useMemo(() => {
    switch (notificationType) {
      case NotificationType.COMMENT_PROFILE:
        return (
          <>
            <span>{data.userName}</span> Paliko apie jus atsiliepimą
          </>
        );
      case NotificationType.LIKED_ADVERTISEMENT:
        return (
          <>
            <span>{data.userName}</span> Patiko jūsų skelbimas
          </>
        );
      case NotificationType.UNREAD_MESSAGE:
        return (
          <>
            Naujas pranešimas nuo <span>{data.userName}</span>
          </>
        );
      case NotificationType.NEW_RESERVATION:
        return (
          <>
            Klientas <span>{data.userName}</span> Rezervavo vizitą!
          </>
        );
    }
  }, [notificationType, data.userName]);

  const notificationIcon = useMemo(() => {
    switch (notificationType) {
      case NotificationType.COMMENT_PROFILE:
        return (
          <div
            className={`${styles.notificationIcon} ${styles.notificationIcon__comment}`}
          >
            {<BellIcon primary='#5D55FA' secondary='#5D55FA' />}
          </div>
        );
      case NotificationType.LIKED_ADVERTISEMENT:
        return (
          <div
            className={`${styles.notificationIcon} ${styles.notificationIcon__like}`}
          >
            {<BellIcon primary='#FF6A55' secondary='#FF6A55' />}
          </div>
        );
      case NotificationType.NEW_RESERVATION:
        return (
          <div
            className={`${styles.notificationIcon} ${styles.notificationIcon__green}`}
          >
            {<BellIcon primary='#2DC47B' secondary='#2DC47B' />}
          </div>
        );
      default:
        return <BellIcon />;
    }
  }, [notificationType]);

  const calculatedDate = useMemo(() => {
    const elapsed = new Date().getTime() - new Date(createdOn).getTime();

    if (elapsed < 1000 * 60 * 60) {
      return 'Prieš mažiau nei valandą';
    }

    if (elapsed < 1000 * 60 * 60 * 24) {
      const elapsedHours = Math.floor(elapsed / 1000 / 60 / 60);
      let text =
        elapsedHours === 1
          ? 'valandą'
          : elapsedHours > 9
            ? 'valandų'
            : 'valandas';

      return `Prieš ${Math.floor(elapsed / 1000 / 60 / 60)} ${text}`;
    }

    const elapsedDays = Math.floor(elapsed / 1000 / 60 / 60 / 24);
    let text =
      elapsedDays === 1 ? 'dieną' : elapsedDays > 9 ? 'dienų' : 'dienas';

    return `Prieš ${Math.floor(elapsed / 1000 / 60 / 60 / 24)} ${text}`;
  }, [createdOn]);

  return (
    <div className={styles.notificationItem}>
      {notificationIcon}
      <div className={styles.notificationTextContainer}>
        <p className={styles.notificationText}>{notificationText}</p>
        <p className={styles.notificationDate}>{calculatedDate}</p>
      </div>
    </div>
  );
};

type MessageMenuItemProps = {
  title?: string;
  createdOn: string;
  user?: User;
  previewLastMessage?: Message;
  lastSeenMessageId?: number;
};

const MessageMenuItem: React.FC<MessageMenuItemProps> = ({
  title,
  user,
  previewLastMessage,
  lastSeenMessageId,
  createdOn,
}) => {
  return (
    <motion.div className={styles.messageItem}>
      <img
        className={styles.messageAvatarIcon}
        src={user?.avatarUrl ?? avatarPlaceHolderIcon}
        alt='avatar'
      />
      <div className={styles.messageTextContainer}>
        <p className={styles.upperText}>
          {title ?? user?.name}
          <span>
            {new Date(
              previewLastMessage?.createdOn ?? createdOn
            ).toLocaleTimeString('lt-LT', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </p>
        <p className={styles.lowerText}>
          <div
            dangerouslySetInnerHTML={{
              __html: trimTextWithHtml(previewLastMessage?.message ?? ' ', 25),
            }}
          />
        </p>
      </div>
      <div className={styles.messageNotificatinoContainer}>
        <div
          className={styles.messageNotificationIcon}
          style={{
            backgroundColor:
              (lastSeenMessageId ?? 0) < (previewLastMessage?.id ?? 0) &&
                lastSeenMessageId !== undefined &&
                previewLastMessage !== undefined
                ? '#5D55FA'
                : '#E9E9E9',
          }}
        />
      </div>
    </motion.div>
  );
};

const LightBoxElement: React.FC = () => {
  const { files, openLightBox } = useAppSelector((state) => state.serviceModal);
  const dispatch = useAppDispatch();

  const proccessedFiles = useMemo(() => {
    const lightBoxFiles: Slide[] = [];

    for (const file of files ?? []) {
      if (file.mimeType?.includes('video')) {
        lightBoxFiles.push({
          type: 'video',
          sources: [
            {
              src: file.fileName ?? '',
              type: file.mimeType,
            },
          ],
        });
      } else if (file.mimeType?.includes('image')) {
        lightBoxFiles.push({
          type: 'image',
          src: file.fileName ?? '',
        });
      }
    }

    return lightBoxFiles;
  }, [files]);

  return (
    <Lightbox
      plugins={[Video]}
      open={openLightBox}
      close={() => {
        dispatch(handleLightBox({ isOpen: false }));
      }}
      slides={proccessedFiles}
    />
  );
};

const CallbackMainModal: React.FC = () => {
  const { callbackModal } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setCallBackModal());

    // clear url
    const url = new URL(window.location.href);
    url.searchParams.delete('payment');
    url.searchParams.delete('data');

    window.history.pushState({}, '', url.toString());
  };

  useMount(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentStatus = params.get('payment');

    if (paymentStatus !== 'success') {
      dispatch(setCallBackModal()); // closed modal

      return;
    }

    const data = JSON.parse(
      atob(params.get('data') ?? '')
    );

    if (data?.expireData !== undefined) {
      const date = new Date(data.expireData);
      const currentDate = new Date();

      if (date < currentDate) {
        handleClose(); // closed modal
      } else {
        dispatch(setCallBackModal({ data }));
      }
    }
  });


  return (
    <Modal open={Boolean(callbackModal)} onClose={handleClose}>
      <PaymentInfoModalInnerData
        timeFrom={callbackModal?.data.timeFrom ?? ''}
        timeTo={callbackModal?.data.timeTo ?? ''}
        handleClose={handleClose}
      />
    </Modal>
  );
};


type SubscriptionModalProps = {
  isOpen?: boolean;
  onClose: () => void;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal open={Boolean(isOpen)} onClose={onClose}>
      < SubscriptionModalInner onClose={onClose} />
    </Modal>
  )
}