import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  extraOptions,
  MainPanelSegments,
  menuOptions,
  menuOptionsDropdown,
} from '../helpers/constants';
import { AlertColor } from '@mui/material';
import { FormSegment } from '../../pages/public/login-page/LoginPage';

export interface Notification {
  isOpen: boolean;
  severity: AlertColor;
  message: string;
}

interface AuthenticationData {
  segment: FormSegment;
  email: string;
}

export interface GlobalState {
  currentMainPanelSegment?: MainPanelSegments;
  segmentHeaderData?: {
    label: string;
    underline?: string;
  };

  notification: Notification;

  // authentication actions
  authentication?: AuthenticationData;

  globalLoading?: boolean;
  initialized?: boolean;

  callbackModal?: {
    data: any;
  };
}

const initialState: GlobalState = {
  currentMainPanelSegment: MainPanelSegments.USER_SERVICES,
  segmentHeaderData: {
    label: 'Skelbimai',
    underline: 'Surask savo tinkamiausia tatuiruočių meistrą',
  },
  notification: {
    isOpen: false,
    severity: 'info',
    message: '',
  },

  authentication: {
    segment: FormSegment.LOGIN,
    email: '',
  },

  globalLoading: false,
  initialized: false, // is state initialized
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setCurrentMainPanelSegment: (
      state,
      action: PayloadAction<{
        segment: MainPanelSegments;
        serviceId?: number;
        name?: string;
      }>
    ) => {
      state.currentMainPanelSegment = action.payload.segment;

      const allOptions = [
        ...menuOptions,
        ...menuOptionsDropdown,
        ...extraOptions,
      ];

      const selectedOption = allOptions.find(
        (option) => option.value === action.payload.segment
      );

      // custom label hooks
      if (selectedOption?.value === MainPanelSegments.ADJUST_SERVICE) {
        const { serviceId } = action.payload;

        if (serviceId !== undefined) {
          state.segmentHeaderData = {
            label: 'Redaguoti skelbimą',
            underline: 'Redaguok savo skelbimą',
          };
          return state;
        }
      }

      if (
        selectedOption?.value === MainPanelSegments.PROFILE &&
        action.payload.name
      ) {
        state.segmentHeaderData = {
          label: `${action.payload.name} profilis`,
          underline: undefined,
        };
      } else {
        state.segmentHeaderData = {
          label: selectedOption?.label || '',
          underline: selectedOption?.underline || '',
        };
      }

      return state;
    },

    displayNotification: (
      state,
      action: PayloadAction<Pick<Notification, 'message' | 'severity'>>
    ) => {
      state.notification = { isOpen: true, ...action.payload };
    },
    closeNotification: (state) => {
      state.notification.isOpen = false;
    },

    setAuthenticationData: (
      state,
      action: PayloadAction<Partial<AuthenticationData>>
    ) => {
      if (!state.authentication) {
        state.authentication = { segment: FormSegment.LOGIN, email: '' };
      }

      state.authentication = { ...state.authentication, ...action.payload };
    },
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.globalLoading = action.payload;
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setCallBackModal: (
      state,
      action: PayloadAction<{ data: any } | undefined>
    ) => {
      state.callbackModal = action.payload;
    },
  },
});

export default globalSlice.reducer;
export const {
  setCurrentMainPanelSegment,
  displayNotification,
  closeNotification,
  setAuthenticationData,
  setGlobalLoading,
  setInitialized,
  setCallBackModal,
} = globalSlice.actions;
