import { useAsync } from "react-use";
import request from "../../global/helpers/utils";
import { useAppDispatch, useAppSelector } from "../../global/GlobalStore";
import { setCurrentMainPanelSegment, setGlobalLoading } from "../../global/reducers/GlobalReducer";
import { MainPanelSegments } from "../../global/helpers/constants";
import { setUserData } from "../../global/reducers/UserReducer";
import { useMemo, useState } from "react";

import styles from './SubscriptionPanel.module.scss';
import _ from "lodash";
import SubscriptionPanelAddWorker from "./people-listing/SubscriptionPanelAddWorker";
import AdjustSubscription from "./adjust-subscription/AdjustSubscription";
import { setData } from "../../global/reducers/SubscriptionReducer";

export enum SubscriptionPanelSegments {
    ADD_MASTER = 'Pridėti meistrą',
    SUBSCRIPTION_PLAN = 'Keisti InkSpiration planą'
}


const SubscriptionPanel: React.FC = () => {
    const { permissions } = useAppSelector((state) => state.user);

    const [currentSegment, setCurrentSegment] = useState(
        SubscriptionPanelSegments.ADD_MASTER
    );

    const [__, setBillingPortalUrl] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    useAsync(async () => {
        dispatch(setGlobalLoading(true));

        const response = await request<{
            subscriptionData: {
                active: boolean;
                portalUrl: string;
                isPartOfCompany: boolean;
            }
        }>({
            url: '/v3/private/subscription',
            method: 'GET',
        }, dispatch, false);


        // instance of subscription exists, redirect to billing portal
        if (!response.subscriptionData.active) {
            dispatch(setCurrentMainPanelSegment({ segment: MainPanelSegments.PROFILE }));
            dispatch(setGlobalLoading(false));
            window.location.href = response.subscriptionData.portalUrl;
        }


        dispatch(setUserData({ hasSubscriptionActive: response.subscriptionData.active }));
        dispatch(setData({ isSubscriptionActive: response.subscriptionData.active, billingPortalUrl: response.subscriptionData.portalUrl, isSubscriptionOwner: !response.subscriptionData.isPartOfCompany }));
        setBillingPortalUrl(response.subscriptionData.portalUrl);
        // Fetch subscription data
        // if no subscription lead to stripe subscription upgrade/order page

        // if subscription exists, update user subscription data
        dispatch(setGlobalLoading(false));
    }, []);


    const availableSegments = useMemo(() => {
        const segments: SubscriptionPanelSegments[] = [];

        segments.push(SubscriptionPanelSegments.ADD_MASTER);
        segments.push(SubscriptionPanelSegments.SUBSCRIPTION_PLAN);

        return segments;
    }, [permissions]);


    const segmentElement = useMemo(() => {
        switch (currentSegment) {
            case SubscriptionPanelSegments.ADD_MASTER:
                return <SubscriptionPanelAddWorker />
            case SubscriptionPanelSegments.SUBSCRIPTION_PLAN:
                return <AdjustSubscription />

            default:
                return null;
        }
    }, [currentSegment]);

    return (
        <div className={styles.panel}>
            <div className={styles.leftContailer}>
                {_.map(availableSegments, (e, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentSegment(e)}
                        className={`${styles.navBtn} ${e === currentSegment ? styles.navBtn_active : ''
                            }`}
                    >
                        {e}
                    </button>
                ))}
            </div>
            <div className={styles.rightContailer}>{segmentElement}</div>
        </div>
    )
}

export default SubscriptionPanel;