import styles from './styles/About.module.scss';

import background_vector_image from '../../../../assets/public/about/vector_background_image.webp';

import sc_hidden from '../../../../assets/public/about/Rectangle-16.webp';
import sc_visible from '../../../../assets/public/about/Rectangle-13.webp';

import clock_icon from '../../../../assets/icons/clock.svg';
import image_icon from '../../../../assets/icons/image.svg';
import currency_icon from '../../../../assets/icons/dollar-circle.svg';

import { AnimatePresence, motion } from 'framer-motion';

const About: React.FC = () => {
  return (
    <AnimatePresence>
      <motion.div
        layout
        className={styles.aboutContainer}
        initial={{ y: 50 }}
        whileInView={{ y: 0 }}
        viewport={{
          once: true,
        }}
      >
        <div
          style={{ backgroundImage: `url(${background_vector_image})` }}
          className={styles.backgroundImage}
        />
        <p className={styles.title}>Kas yra "Tatuiruočių skelbimai"?</p>
        <p className={styles.underline}>
          Tinklo specializacija yra gan plati, bet pagrindinės kryptys - tai
          tatuiravimas ir auskarų vėrimas. Šioje srityje laikomės aukštų
          standartų. Daugelis specialistų iki šiol dirba netinkamomis sąlygomis
          ir nekokybiška įranga, nesilaiko saugumo taisyklių. O svarbiausia, be
          garantijų ir atsakomybės.
        </p>
        <div className={styles.scContainer}>
          <motion.img
            src={sc_hidden}
            alt='sc_hiddenx1'
            className={styles.sc_hidden1}
          />
          <img
            src={sc_hidden}
            alt='sc_hiddenx2'
            className={styles.sc_hidden2}
          />
          <img
            src={sc_hidden}
            alt='sc_hiddenx3'
            className={styles.sc_hidden3}
          />
          <img
            src={sc_hidden}
            alt='sc_hiddenx4'
            className={styles.sc_hidden4}
          />
          <img
            src={sc_visible}
            alt='sc_visible'
            className={styles.sc_visible}
          />
        </div>
        <div className={styles.list}>
          <motion.div
            className={styles.listItem}
            initial={{ opacity: 0, x: -50 }}
            exit={{ opacity: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.0 }}
            viewport={{ once: true, amount: 0.1 }}
            layout
          >
            <div className={styles.listImage}>
              <img src={clock_icon} alt='clock' />
            </div>
            <p className={styles.listTitle}>
              Įkelti skelbimą užtrunka tik 5 minutes
            </p>
          </motion.div>
          <motion.div
            className={styles.listItem}
            initial={{ opacity: 0, x: -50 }}
            exit={{ opacity: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5 }}
            viewport={{ once: true, amount: 0.1 }}
            layout
          >
            <div className={styles.listImage}>
              <img src={image_icon} alt='icon' />
            </div>
            <p className={styles.listTitle}>
              Lengvai įkelkite ir tvarkykite skelbimus
            </p>
          </motion.div>
          <motion.div
            className={styles.listItem}
            initial={{ opacity: 0, x: -50 }}
            exit={{ opacity: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 1 }}
            viewport={{ once: true, amount: 0.1 }}
            layout
          >
            <div className={styles.listImage}>
              <img src={currency_icon} alt='currency' />
            </div>
            <p className={styles.listTitle}>
              Mažiausios skelbimų kainos Lietuvoje
            </p>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default About;
