import { useCallback, useState } from 'react';
import styles from './SubscriptionPanelAddWorker.module.scss';
import { addWorker, removeWorker, setData, SubscriptionWorkerData } from '../../../global/reducers/SubscriptionReducer';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../global/GlobalStore';
import request from '../../../global/helpers/utils';

import defaultIcon from '../../../assets/icons/avatar-placeholder.svg';
import { useAsync } from 'react-use';
import { setCurrentMainPanelSegment } from '../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../global/helpers/constants';
import { setData as setProfileData } from '../../../global/reducers/ProfileReducer';


export default function SubscriptionPanelAddWorker() {
    const dispatch = useAppDispatch();
    const { isSubscriptionOwner } = useAppSelector((state) => state.subscriptionPanel);

    useAsync(async () => {
        const response = await request<{ data: SubscriptionWorkerData[] }>({
            url: '/v3/private/subscription/workers',
            method: 'GET',
        }, undefined, false);

        dispatch(setData({ workers: response.data }));
    });


    return (
        <div className={styles.container}>
            <p className={styles.headline}>Pridėti naują meistrą</p>
            <div className={styles.spacer} />
            {Boolean(!isSubscriptionOwner) ? <div>
                <p className={styles.underline}>Jūs nesate, šios prenumeratos savininkas, todėl negalite atlikti koregavimo veiksmų</p>
            </div> : (
                <p className={styles.underline}>Įtraukite meistrus į savo partnerystę</p>
            )}
            <SubscriptionPanelLookupWorker />
            <SubscriptionPanelWorkerEditorList />
        </div>
    );
}

const SubscriptionPanelLookupWorker: React.FC = () => {
    const [workerList, setWorkerList] = useState<SubscriptionWorkerData[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    const { isSubscriptionOwner } = useAppSelector((state) => state.subscriptionPanel);

    const dispatch = useAppDispatch();

    const handleSearchValueChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value);
        },
        []
    );

    const handleLookUp = useCallback(async () => {
        if (!isSubscriptionOwner) {
            return;
        }

        const result = await request<{ data: SubscriptionWorkerData[] }>({
            method: 'GET',
            url: '/v3/private/subscription/lookup/worker',
            params: { search: searchValue }
        }, dispatch, true);

        setWorkerList(result.data);
    }, [searchValue, dispatch, isSubscriptionOwner]);

    const handleAddWorker = useCallback(async (worker: SubscriptionWorkerData) => {
        if (!isSubscriptionOwner) {
            return;
        }

        let added = false;
        // add worker to subscription
        const result = await request({
            method: 'POST',
            url: '/v3/private/subscription/add/worker',
            data: {
                userId: worker.id,
            }
        }, dispatch, true, () => { added = false });

        if (result) {
            added = true;
        }

        if (!added) {
            return;
        }

        dispatch(addWorker(worker));

        setWorkerList(_.filter(workerList, (w) => w.id !== worker.id));

    }, [dispatch, workerList, isSubscriptionOwner]);

    return (
        <div className={`${styles.container} ${styles.container_minor}`}>
            <div className={styles.inputContainer}>
                <p className={styles.inputLabel}>Įveskite vardą, pavardę, įmonės pavadinimą arba el. pašto adresą</p>
                <div className={styles.inputBox}>
                    <input value={searchValue} onChange={handleSearchValueChange} />
                    <button onClick={handleLookUp} disabled={!isSubscriptionOwner}>Ieškoti</button>
                </div>
            </div>
            <div className={styles.grid}>
                {_.map(workerList, (worker, index) => {
                    return (
                        <SubscriptionPanelWorkerEditor
                            key={index}
                            worker={worker}
                            action={{ title: 'Pridėti', handler: handleAddWorker }}
                            isDisabled={!isSubscriptionOwner}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const SubscriptionPanelWorkerEditorList: React.FC = () => {
    const dispatch = useAppDispatch();
    const { workers, isSubscriptionOwner } = useAppSelector((state) => state.subscriptionPanel);

    const handleRemoveWorker = useCallback(async (worker: SubscriptionWorkerData) => {
        if (!isSubscriptionOwner) {
            return;
        }

        let removed = false;
        // add worker to subscription
        const result = await request({
            method: 'POST',
            url: '/v3/private/subscription/remove/worker',
            data: {
                userId: worker.id,
            }
        }, dispatch, true, () => { removed = false });

        if (result) {
            removed = true;
        }

        if (!removed) {
            return;
        }

        dispatch(removeWorker(worker));
    }, [dispatch, isSubscriptionOwner]);

    if (_.isEmpty(workers)) {
        return null;
    }

    return <div className={styles.containerSub}>
        <p className={styles.headline}>Jūsų meistrai</p>
        <div className={styles.grid}>
            {_.map(workers, (worker, index) => {
                return (
                    <SubscriptionPanelWorkerEditor
                        key={index}
                        worker={worker}
                        action={{ title: 'Pašalinti', handler: handleRemoveWorker }}
                        isDisabled={!isSubscriptionOwner}
                    />
                );
            })}
        </div>
    </div>;
};

type SubscriptionPanelWorkerEditorProps = {
    worker: SubscriptionWorkerData;
    action: { title: string; handler: (worker: SubscriptionWorkerData) => void };
    isDisabled?: boolean;
};

const SubscriptionPanelWorkerEditor: React.FC<
    SubscriptionPanelWorkerEditorProps
> = ({ worker, action, isDisabled }) => {
    const dispatch = useAppDispatch();

    const handleOpenUserProfile = useCallback(() => {
        // open profile but for this user
        dispatch(setProfileData({ userId: worker.id }));
        dispatch(
            setCurrentMainPanelSegment({
                segment: MainPanelSegments.PROFILE,
                name: worker.name,
            })
        );
    }, [worker.id, worker.name, dispatch]);

    return (
        <div className={styles.workerItemContainer}>
            <div className={styles.workerInfo}>
                <img src={_.isEmpty(worker.avatarUrl) ? defaultIcon : worker.avatarUrl} alt={worker.name} onClick={handleOpenUserProfile} />
                <p>{worker.name}</p>
            </div>
            <button onClick={() => action.handler(worker)} disabled={isDisabled} title={isDisabled ? 'Šis veiksmas galimas, tik inkspiration business paskyros savininkui' : ''}>{action.title}</button>
        </div>
    );
};
