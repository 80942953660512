import { useAppSelector } from "../../../global/GlobalStore";

import styles from './SubscriptionModal.module.scss';

import bulletImg from '../../../assets/images/Group_56.webp';
import crossIcon from '../../../assets/icons/add.svg';

const SubscriptionModalInner: React.FC<{
    onClose: () => void;
}> = ({ onClose }) => {
    const { billingPortalUrl } = useAppSelector((state) => state.subscriptionPanel);


    const handleRedirect = () => {
        if (!billingPortalUrl) return;

        window.location.href = billingPortalUrl;
        onClose();
    }

    return (
        <div className={styles.container}>
            <div className={styles.closeBtnContainer}>
                <button className={styles.closeBtn} onClick={onClose}>
                    <img src={crossIcon} alt='naikinti' />
                </button>
            </div>
            <div className={styles.header}>Inkspiration Business</div>
            <div className={styles.price}>€20.00</div>
            <div className={styles.underline}>Mėnesinis planas</div>
            <div className={styles.divider} />
            <div className={styles.card}>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Prenumeratai suteikiamas 90 dienų bandomasis laikotarpis</p>
                </div>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Iškeltas profilis paieškos rezultatuose pagal klientų filtrus</p>
                </div>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Neribotas užklausų skaičius</p>
                </div>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Suteikiama galimybė į savo prenumeratą pakviesti, kitus meistrus</p>
                </div>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Galimybė įkelti savo skelbimus</p>
                </div>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Pakviesti meistrai taip pat galės kelti skelbimus, kurie bus žymimi kaip jūsų salono skelbimai</p>
                </div>
                <div className={styles.features}>
                    <img src={bulletImg} alt='bullet' />
                    <p>Greita ir patikima klientų aptarnavimo komandos pagalba</p>
                </div>
            </div>
            <div className={styles.btnContainer}>
                <button onClick={handleRedirect} className={styles.btn}>Įsigyti prenumeratą</button>
            </div>
        </div>
    );
}

export default SubscriptionModalInner;